// extracted by mini-css-extract-plugin
export var container = "RegisterPage-module--container--1THVU";
export var content = "RegisterPage-module--content--f9Gf0";
export var form = "RegisterPage-module--form--2hnBt";
export var title = "RegisterPage-module--title--shQj8";
export var description = "RegisterPage-module--description--Jacme";
export var link = "RegisterPage-module--link--jd7FT";
export var inputContainer = "RegisterPage-module--inputContainer--2hU9U";
export var passwordRules = "RegisterPage-module--passwordRules--10Oco";
export var button = "RegisterPage-module--button--WBUgz";
export var footerContainer = "RegisterPage-module--footerContainer--2eKVf";