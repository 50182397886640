import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { trackingGA } from '@merchx-v2/helpers'
import { graphql } from 'gatsby'
import { useSiteMetadata } from 'hooks/use-site-metadata';
import { navigate } from 'utils/navigate'
import { Button, Footer, Header, Input, Notification, SEO } from 'components'

import { actionCreators as actionHomeCreators, selectMenu } from 'state/HomePage/reducer'
import * as actions from 'state/LoginPage/actions'
import { isPasswordValid, isEmailValid } from 'state/LoginPage/actions'
import { selectors } from 'state/LoginPage/reducer'
import * as styles from './RegisterPage.module.scss'

const RegisterPage = props => {
  const {
    loginState,
    menu,

    customerAccountRegister,
    resetMenuFilters,
    setMenuCollection,

    data,
  } = props

  const { landing } = useSiteMetadata()

  const [isShowErrors, setIsShowErrors] = useState(false)
  const [notification, showNotification] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    showNotification(loginState.UIState.error)
  }, [loginState.UIState.error])

  const handleCollectionClicked = collection => {
    setMenuCollection(collection)
  }

  const handleReset = () => {
    resetMenuFilters()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setIsShowErrors(true)
    if (isEmailValid(email).isValid && isPasswordValid(password).isValid && password === repeatPassword) {
      const { payload: isRegistered } = await customerAccountRegister({
        campaignId: landing.CAMPAIGN.id,
        landingId: landing.LANDING_ID,
        email,
        password
      })
      if (isRegistered) {
        showNotification('You are registered successfully!')
        navigate(loginState.pageAfterLogin)
        return true
      }
    }
  }

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } = data?.markdownRemark?.frontmatter || {}

  return (
    <div className={styles.container}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
        pathname='/register'
      />
      <div className={styles.content}>
        <Header menu={menu} onCollectionClicked={handleCollectionClicked} onResetCollection={handleReset} />
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.title}>Create an Account</div>
          <p className={styles.description}>Create an account with Fan Made Fits to start shopping. Free forever.</p>

          <div className={styles.inputContainer}>
            <Input
              placeholder='Email Address*'
              onChange={setEmail}
              isValid={isEmailValid(email).isValid}
              isShowErrors={isShowErrors}
              errorMessage={isEmailValid(email).error}
              name='emailC'
              autoComplete='email'
              bigInput
            />
          </div>

          <div className={styles.inputContainer}>
            <Input
              placeholder='Password*'
              onChange={setPassword}
              isValid={isPasswordValid(password).isValid}
              isShowErrors={isShowErrors}
              errorMessage={isPasswordValid(password).error}
              bigInput
              inputType='password'
            />
          </div>

          <div className={styles.inputContainer}>
            <Input
              placeholder='Repeat Password*'
              onChange={setRepeatPassword}
              isValid={repeatPassword === password ? true : false}
              isShowErrors={isShowErrors}
              errorMessage='Password does not match'
              bigInput
              inputType='password'
            />
          </div>

          <p className={styles.passwordRules}>Password must contain at least 6 symbols: one upper letter, one number, and one special character.</p>

          <div className={styles.button}>
            <Button value='Create an Account' loading={loginState.UIState.isLoading} className='buttonBlue' />
          </div>
        </form>
      </div>

      <Notification message={notification} />
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loginState: selectors.selectLoginState(state),
  menu: selectMenu(state)
})

const mapDispatchToProps = {
  customerAccountRegister: actions.customerAccountRegister,

  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)

export const query = graphql`
  query RegisterPage {
    markdownRemark(fields: {slug: {eq: "/register/"}}) {
      frontmatter {
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`
